import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";
import { checkAudioFormat } from "../../Util/helpers";

import { placeholderImage } from "../../Assets/images";

import "./style.css";
import BASEURL from "../../Config/global";

const AddStory = () => {
  const navigate = useNavigate();

  const [storyOptions, setStoryOptions] = useState([]);
  const [subCategories, setSubCategories] = useState([])
  const [mobileAudioDuration, setMobileAudioDuration] = useState(null);

  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [featuredState, setFeaturedState] = useState(false);
  const [story, setStory] = useState(null);
  const [storyError, setStoryError] = useState(false);

  const [storyImage, setStoryImage] = useState(null);
  const [storyThumbnail, setStoryThumbnail] = useState(null);

  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchStory() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/stories/storiescategories`
        );
        setStoryOptions(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchStory();
  }, []);
  useEffect(()=>{
    if(formData["category"]){
      getSubCategories(formData["category"])
    }
  },[formData["category"]])
  const getSubCategories = async (soundcategory) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    fetch(`${BASEURL}/api/stories/storiescategories/${soundcategory}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          const { category_data } = result?.data[0]
          setSubCategories(category_data)
        }
      })
      .catch((error) => console.error(error));
  }

  console.log(formData);

  useEffect(() => {
    const firstCategory = storyOptions[0]?.id.toString();
    setFormData({ category: firstCategory });
  }, [storyOptions]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeCategory = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value, soundSubCategory:"undefined" });
  }

  const handleStoryChange = (event) => {
    const audioResult = checkAudioFormat(event);
    handleFileChange(event)
    setStory(audioResult.selectedFile);
    setStoryError(audioResult.error);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();

        // Load the file into an audio context to get the duration
        reader.onload = (e) => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(e.target.result, (buffer) => {
                const duration = buffer.duration;
                setMobileAudioDuration(duration)
                console.log(`The duration of the file is ${duration} seconds.`);
            });
        };

        reader.readAsArrayBuffer(file);
    }
};
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file === null || file === "undefined") {
      setStoryImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setStoryImage(reader.result);
      };
    }
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    setSelectedThumbnail(file);

    if (file === null || file === "undefined") {
      setStoryThumbnail(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setStoryThumbnail(reader.result);
      };
    }
  };

  const postStory = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("image", selectedImage);
    formDataToSend.append("thumbnail", selectedThumbnail);
    formDataToSend.append("audio", story);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("premium", formData.premium);
    if (formData.soundSubCategory && formData.soundSubCategory !== 'undefined') {
      formDataToSend.append("storiescategory", formData.soundSubCategory);
    } else {
      formDataToSend.append("storiescategory", formData.category);
    }
    if (mobileAudioDuration) {
      formDataToSend.append("duration", mobileAudioDuration);
    }
    formDataToSend.append("featured", featuredState);
    formDataToSend.append("naration", true);

    setLoader(true);
    try {
      const response = await axios.post(
        `${BASEURL}/api/stories/`,
        formDataToSend,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.error === false) {
        successModal.fire({
          text: "Story Added Successfully",
          confirmButtonText: "Continue",
        });
        setLoginError({ error: false, text: "" });
        setLoader(false);
        navigate("/story-management");
      } else {
        setLoginError({ error: true, text: response.data.message });
        setLoader(false);
      }
    } catch (error) {
      setLoginError({
        error: true,
        text: "An error occurred. Please try again later.",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    questionModal
      .fire({
        title: "Do you want to add this Story?",
        confirmButtonText: "Add",
      })
      .then((result) => {
        if (result.isConfirmed) {
          postStory();
        }
      });
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Story
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-lg-6 mb-2">
                    <CustomInput
                      label="Title"
                      labelClass="mainLabel"
                      required
                      type="text"
                      name="title"
                      value={formData.title || ""}
                      placeholder="Enter Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Type</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="premium"
                        required
                        value={"false"}
                        className="me-1"
                        onChange={handleChange}
                      />
                      Free
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="premium"
                        required
                        value={"true"}
                        className="me-1"
                        onChange={handleChange}
                      />
                      Premium
                    </label>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Audio*</p>
                    <label>
                      <div className="audioInput">
                        <span>Select Audio File</span>
                      </div>
                      {story && (
                        <p className="audioInputName oneLine">{story.name}</p>
                      )}
                      {storyError && (
                        <p className="audioInputName">
                          Please select correct file format
                        </p>
                      )}
                      <input
                        type="file"
                        name="story"
                        accept="audio/*"
                        className="d-none"
                        required
                        onChange={handleStoryChange}
                      />
                    </label>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Select Category*</p>
                    {storyOptions && (
                      <select
                        name="category"
                        id="category"
                        className="mainInput w-auto"
                        required
                        onChange={handleChangeCategory}
                      >
                        {storyOptions.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Select Sub Category*</p>
                    {storyOptions && (
                      <select
                        name="soundSubCategory"
                        id="soundSubCategory"
                        className="mainInput w-auto"
                        required
                        value={formData.soundSubCategory}
                        onChange={handleChange}
                      >
                        <option value={"undefined"}>No item selected</option>
                        {subCategories?.length > 0 && subCategories.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Thumbnail*</p>
                    <label>
                      <div className="thumbnailInput">
                        {storyThumbnail ? (
                          <img src={storyThumbnail} alt="Thumbnail" />
                        ) : (
                          <img src={placeholderImage} alt="Thumbnail" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        required
                        onChange={handleThumbnailChange}
                      />
                    </label>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <p className="mainLabel">Image*</p>
                    <label>
                      <div className="imageInput">
                        {storyImage ? (
                          <img src={storyImage} alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        required
                        onChange={handleImageChange}
                      />
                    </label>
                  </div>
                  <div className="col-12 mb-2">
                    <input
                      type="checkbox"
                      name="featured"
                      id="featured"
                      onChange={() => {
                        setFeaturedState(!featuredState);
                      }}
                    />
                    <label htmlFor="featured" className="mainLabel ms-1">
                      Featured
                    </label>
                  </div>
                </div>
                {loginError.error == true && (
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="secondaryText">{loginError.text}</p>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <CustomButton
                      type="submit"
                      variant="primaryButton"
                      text="Add"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default AddStory;

import React from 'react'
import BASEURL from '../../Config/global'
import { placeholderImage } from '../../Assets/images'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const EditImageComponent = ({ handleImageChange, soundImage, thumbnail, handleRemoveImage }) => {
    const handleOnClickForRemoveImage = (event) => {
        event.preventDefault();
        handleRemoveImage();
    }
    return (
        <div className="col-lg-6 mb-2">
            <p className="mainLabel ">Image*</p>
            <label className='icon-wrapper'>
                <div className="icon-on-top-right" onClick={handleOnClickForRemoveImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                </div>
                <div className="imageInput">
                    {soundImage ? (
                        <img src={`${soundImage}`} alt="Main" />
                    ) : (
                        (thumbnail == '/media/assets/no_image.png' || thumbnail == null) ? <img src={`${placeholderImage}`} alt="Main" /> :
                            <img src={`${thumbnail}`} alt="Main" />
                    )}
                </div>
                <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleImageChange}
                />
            </label>
        </div>
    )
}
export const AddImageComponent = ({ handleImageChange, soundImage, handleRemoveImage, inputClass='col-lg-3' }) => {
    return (
        <div className={`${inputClass} mb-2`}>
            <p className="mainLabel ">Image*</p>
            <label className='icon-wrapper'>
                <div className="icon-on-top-right" onClick={handleRemoveImage}>
                    <FontAwesomeIcon className="cross-icon-style" icon={faTimesCircle} />
                </div>
                <div className="imageInput">
                    {soundImage ? (
                        <img src={soundImage} alt="Main" />
                    ) : (
                        <img src={placeholderImage} alt="Main" />
                    )}
                </div>
                <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    required
                    name="banner"
                    onChange={handleImageChange}
                />
            </label>
        </div>
    )
}



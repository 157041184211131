import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL, { FE_BASE_URL } from "../../Config/global";
import { placeholderImage } from "../../Assets/images";
import CustomInput from "../../Components/CustomInput";
import CustomLoader from "../../Components/CustomLoader";


import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from "draft-convert";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal } from "react-bootstrap";




const AddCoupon = () => {
  const navigate = useNavigate();
  const [inputValue, setinputValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [target, setTarget] = useState(false);
  const [unlimited, setUnlimited] = useState(false);
  const [minDate, setMinDate] = useState(new Date());

  const [formData, setFormData] = useState({});
  const [formImgShow, setFormImgShow] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  
  const [test, setTest] = useState('');

  const [priEditorState, setPriEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [secEditorState, setSecEditorState] = useState(
    () => EditorState.createEmpty(),
  );


  useEffect(() => {
    // 2018-12-31
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    setMinDate(year + '-' + month + '-' + day)
  }, [])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const postData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("code", formData.code);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("code_description", formData.code_description);
    if (unlimited) {
      formDataToSend.append("unlimited", unlimited);
    } else {
      let date = formData.expires.split('-');
      date = date[1] + '-' + date[2] + '-' + date[0];
      // console.log(date);
      formDataToSend.append("expires", date);
    }
    if (formData.trialPeriod === "" || !formData.trialPeriod) {
      formDataToSend.append("trial_period", 0);
    }
    else {
      formDataToSend.append("trial_period", formData.trialPeriod);
    }
    formDataToSend.append("type", formData.type);

    // HTML info tray
    formDataToSend.append("pri_company_title", formData.pri_company_title);
    (formData.pri_header_logo_info != undefined && formData.pri_header_logo_info != null) && formDataToSend.append("pri_header_logo_info", formData.pri_header_logo_info);
    (formData.pri_mobile_logo != undefined && formData.pri_mobile_logo != null) && formDataToSend.append("pri_mobile_logo", formData.pri_mobile_logo);
    (formData.pri_desktop_logo != undefined && formData.pri_desktop_logo != null) && formDataToSend.append("pri_desktop_logo", formData.pri_desktop_logo);
    formDataToSend.append("pri_html_info_text", formData.pri_html_info_text);
    formDataToSend.append("sec_company_title", formData.sec_company_title);
    (formData.sec_header_logo_info != undefined && formData.sec_header_logo_info != null) && formDataToSend.append("sec_header_logo_info", formData.sec_header_logo_info);
    (formData.sec_mobile_logo != undefined && formData.sec_mobile_logo != null) && formDataToSend.append("sec_mobile_logo", formData.sec_mobile_logo);
    (formData.sec_desktop_logo != undefined && formData.sec_desktop_logo != null) && formDataToSend.append("sec_desktop_logo", formData.sec_desktop_logo);
    formDataToSend.append("sec_html_info_text", formData.sec_html_info_text);
    setLoader(true);

    try {
      const response = await axios.post(
        `${BASEURL}/api/user/coupon`,
        formDataToSend
      );
      if (!response.data.error) {
        successModal.fire({
          text: "Promo Added Successfully",
          confirmButtonText: "Continue",
        });
        setLoginError({ error: false, text: "" });
        setLoader(false);
        navigate("/promo-management");
      } else {
        setLoginError({ error: true, text: response.data.message });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    if (formData.title && formData.code && formData.price  && formData.type && (formData.expires || unlimited)) {
      questionModal
        .fire({
          title: "Do you want to add this Promo?",
          confirmButtonText: "Add",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData();
            // console.log(formData)
          }
        });
    }
    else {
      setLoginError({ error: true, text: "Please Fill All the Requires Fields" });
    }
  };

  // useEffect(() => {
  //   let html = draftToHtml(convertToRaw(priEditorState.getCurrentContent()));
  //   // console.log(html)
  //   setFormData({ ...formData, 'pri_html_info_text': html });
  // }, [priEditorState]);

  // useEffect(() => {
  //   let html = draftToHtml(convertToRaw(secEditorState.getCurrentContent()));
  //   setFormData({ ...formData, 'sec_html_info_text': html });
  // }, [secEditorState]);

  const regex = "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$";

  const changeMe = (e) => {
    console.log(e, 'change')
    if (e.target.value.length == 2) {
      setTest(e.target.value + '/')
    } else if (e.target.value.length == 5) {
      setTest(e.target.value + '/')
    } else if (e.target.value.length <= 10 || e.target.value.length == '') {
      setTest(e.target.value)
    }
  }

  const inputMe = (e) => {
    if (e.keyCode == 8) {
      setTimeout(() => {
        // console.log(test.slice(0, -1));
        if (e.target.value.length == 3) {
          console.log(test.slice(0, -2));
          setTest(e.target.value.slice(0, -2))
        }
      }, 500);
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // setFormData({ ...formData, [event.target.name]: file });
    setFormData({ ...formData, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // setSoundImage(reader.result);
        // const { name, value } = event.target;
        setFormImgShow({ ...formImgShow, [event.target.name]: reader.result });
        // form.pri_header_logo_info(reader.result)
        // console.log(formData)
      };
    }
  }

  const handleCopy = () => {
    const text = `${FE_BASE_URL}/${isCopied}/${formData.code}`
    navigator.clipboard.writeText(text);
    setShowModal(false)
  }
  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Promo
              </h2>
            </div>
          </div>
          {/* <input onKeyDown={inputMe} onChange={changeMe} type="text" value={test} /> */}
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">

              {/* Coupon Fields */}
              <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Title"
                  labelClass="mainLabel"
                  required
                  type="text"
                  name="title"
                  value={formData.title || ""}
                  placeholder="Enter Title"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Description"
                  labelClass="mainLabel"
                  required
                  type="text"
                  name="description"
                  value={formData.description || ""}
                  placeholder="Enter Description"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 mb-2">
                <CustomInput
                  label="Code Description"
                  labelClass="mainLabel"
                  required
                  type="text"
                  name="code_description"
                  value={formData.code_description || ""}
                  placeholder="Enter Code Description"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg mb-2">
                <CustomInput
                  label="Code"
                  labelClass="mainLabel"
                  required
                  type="text"
                  name="code"
                  value={formData.code || ""}
                  placeholder="Enter Code"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg mb-2">
                <CustomInput
                  label="Trial Period (days)"
                  labelClass="mainLabel"
                  type="number"
                  name="trialPeriod"
                  value={formData.trialPeriod || ""}
                  placeholder=""
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg mb-2">
                <p className="mainLabel mb-4">Subscription Type</p>
                <label className="secondaryText me-3">
                  <input
                    type="radio"
                    name="type"
                    required
                    value={"Monthly"}
                    className="me-1"
                    onChange={handleChange}
                  />
                  Monthly
                </label>
                <label className="secondaryText">
                  <input
                    type="radio"
                    name="type"
                    required
                    value={"Yearly"}
                    className="me-1"
                    onChange={handleChange}
                  />
                  Yearly
                </label>
              </div>
              <div className="col-lg mb-2">
                <CustomInput
                  label="Price"
                  labelClass="mainLabel"
                  required
                  type="number"
                  name="price"
                  value={formData.price || ""}
                  placeholder="Enter Price"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg mb-2">
                <div className="position-relative coupon-expires">
                  <label className="mainLabel">Expires <span style={{ fontSize: '10px' }}>(Check for unlimited)</span></label>
                  <CustomInput
                    type="date"
                    name="expires"
                    value={formData.expires || ""}
                    placeholder=""
                    inputClass="mainInput"
                    onChange={handleChange}
                    min={minDate}
                  />
                  <input type="checkbox" value={"true"} className="checkbox" onChange={() => setUnlimited(!unlimited)} />
                </div>
              </div>
              <div className="col-lg-12 mb-2">
                <label className="secondaryText me-3">
                  <input
                    type="radio"
                    name="coupon-url"
                    value={"promo"}
                    className="me-1"
                    disabled={formData.code ? false : true}
                    onChange={(e) => {
                      if (formData.code) {
                        setIsCopied(e.target.value)
                        setShowModal(true)
                      }
                    }}
                  />
                  Promo url
                </label>
                <label className="secondaryText me-3">
                  <input
                    type="radio"
                    name="coupon-url"
                    value={"promoint"}
                    className="me-1"
                    disabled={formData.code ? false : true}
                    onChange={(e) => {
                      if (formData.code) {
                        setIsCopied(e.target.value)
                        setShowModal(true)
                      }
                    }}
                  />
                  Promoint url
                </label>
              </div>

             

              {/* Primary User */}
              <div className="col-12 my-5">
                <h4 className="fw-bold">Primary User</h4>
              </div>
              <div className="col-lg-3 mb-2">
                <CustomInput
                  label="Company Title"
                  labelClass="mainLabel mb-3"
                  type="text"
                  name="pri_company_title"
                  value={formData.pri_company_title || ""}
                  placeholder="Enter Company Title"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Header Information Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.pri_header_logo_info ? (
                      <img src={formImgShow?.pri_header_logo_info} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="pri_header_logo_info"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Mobile Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.pri_mobile_logo ? (
                      <img src={formImgShow?.pri_mobile_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="pri_mobile_logo"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Desktop Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.pri_desktop_logo ? (
                      <img src={formImgShow?.pri_desktop_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="pri_desktop_logo"
                    onChange={handleImageChange}
                  />
                </label>
              </div>

              <div className="col-lg-12 mb-2">
                <div className="inputWrapper">
                  <label className="mainLabel">HTML Information</label>
                  <textarea
                    rows="10"
                    placeholder=""
                    required=""
                    name="pri_html_info_text"
                    className="mainInput"
                    value={formData.pri_html_info_text}
                    onChange={handleChange}
                  ></textarea></div>
              </div>


              {/* <div className="col-lg-12">
                <div className="App" >
                  <label class="mainLabel">HTML Information</label>
                  <Editor
                    editorState={priEditorState}
                    onEditorStateChange={setPriEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ['inline', 'colorPicker', 'link', 'embedded', 'image', 'blockType', 'fontSize', 'list', 'textAlign', 'remove'],
                      link: {
                        showOpenOptionOnHover: true,
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                    }}
                  />


                </div>
              </div> */}

              {/* Secondary User */}
              <div className="col-12 my-5">
                <h4 className="fw-bold">Household User</h4>
              </div>
              <div className="col-lg-3 mb-2">
                <CustomInput
                  label="Company Title"
                  labelClass="mainLabel mb-3"
                  type="text"
                  name="sec_company_title"
                  value={formData.sec_company_title || ""}
                  placeholder="Enter Company Title"
                  inputClass="mainInput"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Header Information Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.sec_header_logo_info ? (
                      <img src={formImgShow?.sec_header_logo_info} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="sec_header_logo_info"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Mobile Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.sec_mobile_logo ? (
                      <img src={formImgShow?.sec_mobile_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="sec_mobile_logo"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="col-lg-3 mb-2">
                <label className="w-100">
                  <p className="mainLabel">Desktop Image</p>
                  <div className="imageInput couponImageInput">
                    {formImgShow?.sec_desktop_logo ? (
                      <img src={formImgShow?.sec_desktop_logo} className="img" alt="Main" />
                    ) : (
                      <img src={placeholderImage} alt="Main" />
                    )}
                  </div>
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    className="d-none"
                    name="sec_desktop_logo"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              {/* <div className="col-lg-12">
                <div className="App" >
                  <label class="mainLabel">HTML Information</label>
                  <Editor
                    editorState={secEditorState}
                    onEditorStateChange={setSecEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ['inline', 'colorPicker', 'link', 'embedded', 'image', 'blockType', 'fontSize', 'list', 'textAlign', 'remove'],
                      link: {
                        showOpenOptionOnHover: true,
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                    }}
                  />


                </div>
              </div> */}
              <div className="col-lg-12 mb-2">
                <div className="inputWrapper">
                  <label className="mainLabel">HTML Information</label>
                  <textarea
                    rows="10"
                    placeholder=""
                    required=""
                    name="sec_html_info_text"
                    className="mainInput"
                    value={formData.sec_html_info_text}
                    onChange={handleChange}
                  ></textarea></div>
              </div>


            </div>

            {loginError.error == true && (
              <div className="row mb-3">
                <div className="col-12">
                  <p className="secondaryText">{loginError.text}</p>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <CustomButton
                  type="submit"
                  variant="primaryButton"
                  text="Add Promo"
                />
              </div>
            </div>

          </form>
        </div>
      </DashboardLayout >
      {loader && <CustomLoader />}
      <Modal
        show={showModal}
        centered
        className="swap-modal"
        onHide={()=>setShowModal(false)}
      >
        <Modal.Body>
          <div className="pb-5 w-100 d-flex flex-column align-items-center">
            <div className="text-end ms-auto">
              <button
                className="closeButton notButton ms-auto"
                onClick={() => { setShowModal(false); }}
              >
                
              </button>
            </div>
            <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
              <div className="d-flex align-items-center mt-3 pt-3 flex-column">
                <h2 className="mb-0 me-4"><b className="mx-2">{"Copy Url"}</b></h2>
                <br />
                {
                  `${FE_BASE_URL}/${isCopied}/${formData.code}`
                }
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex gap-3 justify-content-center">
              <FontAwesomeIcon
                className="mx-2"
                size={"2xl"}
                icon={faCopy}
                onClick={() => handleCopy()}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default AddCoupon;

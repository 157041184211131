/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 25/10/2023 - 03:00:03
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 25/10/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useState } from "react";
import { useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL from "../../Config/global";
import { placeholderImage } from "../../Assets/images";

const AddSpecial = () => {
  const navigate = useNavigate();

  const [inputValue, setinputValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [target, setTarget] = useState(false);

  const handleChange = (event) => {
    setinputValue(event.target.value);
  };


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);
        console.log(reader)
      };
    }
  };

  const postData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("link", inputValue);
    formDataToSend.append("banner", selectedImage);
    formDataToSend.append("is_new", target);
    try {
      const response = await axios.post(
        `${BASEURL}/api/user/specials`,
        formDataToSend
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue && selectedImage) {
      questionModal
        .fire({
          title: "Do you want to add the specials?",
          confirmButtonText: "Add",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData();
            successModal.fire({
              text: 'Specials Added Successfully',
              confirmButtonText: "Continue",
            });
            navigate("/special-management");
          }
        });
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Special
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-lg-6 mb-2">
                  <div className="inputWrapper">
                    <label className="mainLabel">Banner Link*</label>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="url"
                        placeholder="Enter Banner Url"
                        required=""
                        name="link"
                        className="mainInput"
                        value={inputValue}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <p className="mainLabel">Banner Image*</p>
                  <label>
                    <div className="imageInput">
                      {soundImage ? (
                        <img src={soundImage} alt="Main" />
                      ) : (
                        <img src={placeholderImage} alt="Main" />
                      )}
                    </div>
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      className="d-none"
                      required
                      name="banner"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
                <div className="col-12 mb-2">
                  <input
                    type="checkbox"
                    name="is_new"
                    id="is_new"
                    onChange={() => {
                      setTarget(!target);
                    }}
                  />
                  <label htmlFor="is_new" className="mainLabel ms-1">
                    Open with new tab.
                  </label>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <CustomButton
                    type="button"
                    variant="primaryButton"
                    text="Add"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default AddSpecial;

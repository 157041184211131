import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Link } from 'react-router-dom'
import CustomFilters from '../../Components/CustomFilters'
import CustomTable from '../../Components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import CustomPagination from '../../Components/CustomPagination'
import { Dropdown } from 'react-bootstrap'
import { manageRoleData } from '../../Config/PermissionData'
import BASEURL from '../../Config/global'
import axios from 'axios'
import CustomLoader from '../../Components/CustomLoader'
import { questionModal, successModal } from '../../Components/CustomModal'
import { disabledButtonByPermission } from '../../Components/Permissions'
import { PermissionsContext } from '../../Layout/PermissionContext'

const ManageRoles = () => {
    const [data, setData] = useState();
    const [offset, setOffset] = useState(0);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [filterSearchValue, setFilterSearchValue] = useState("");
    const [sort, setSort] = useState("all");
    const [totalCount, setTotalCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [enteries, setEnteries] = useState(10);
    const { permissions } = useContext(PermissionsContext)

    const rolesHeader = [
        {
            key: "id",
            title: "S.No",
        },
        {
            key: "role",
            title: "Role",
        },
        {
          key: "defaultRole",
          title: "Default Role",
      },
        {
            key: "actions",
            title: "Actions",
        },
    ];

  useEffect(() => {
    getAllRolese()
  }, [])
  useEffect(() => {
    async function fetchData() {
      try {
        setData(data?.filter(f => f.name.toLowerCase().includes(filterSearchValue.trim())))
      } catch (error) {
        console.error(error);
      }
    }
    fetchData()
  }, [enteries, filterSearchValue, dateTo]);
  const getAllRolese = async () => {
    let config = {
      method: 'get',
      url: `${BASEURL}/api/permissions/userrolelist/`,
    };
    await axios.request(config)
      .then((response) => {
        const { data } = response.data
        setData(data)
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const handleDeleteRole = async (id) => {
    let config = {
      method: 'delete',
      url: `${BASEURL}/api/permissions/userrolelist/${id}`,
      headers: {}
    };

    await axios.request(config)
      .then((response) => {
        if (data?.length > 0) {
          setData(data.filter(f => f.id !== id))
        }
        successModal.fire({
          text: "Role deleted successfully",
          confirmButtonText: "Continue",
        });
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSubmit = (id) => {
    // event.preventDefault();
    questionModal
      .fire({
        title: "Do you want to delete this role?",
        confirmButtonText: "Delete",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await handleDeleteRole(id);
        }
      });
  };

  if(!data){
    return <CustomLoader />
  }
  const modulesWisePermission = (condition) =>{
    if(!permissions){
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
      
    return (
        <DashboardLayout>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col-lg-8 mb-2">
                        <h2 className="mainTitle">Roles </h2>
                    </div>
                    <div className="col-lg-4 text-end mb-2">
                        <Link
                            to={"/roles-management/add-role"}
                            className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                        >
                            Add Roles
                        </Link>
                    </div>
                </div>
                <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={data.length}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={setEnteries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                // dateFilter={true}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              // isSearchDropdown={couponsHeader}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={rolesHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.actual_roles ? "Yes" : "No"}</td>
                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle
                              variant="transparent"
                              className="notButton classicToggle"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="tableDropdownMenu"
                            >
                              <Link
                                to={`/roles-management/view-roles/?role_id=${item.id}&role_name=${item.name}`}
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                Edit
                              </Link>
                              <button
                                className={`tableAction ${modulesWisePermission('can_delete')}`}
                                onClick={() => {
                                  handleSubmit(item.id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="tableActionIcon"
                                />
                                Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomPagination
                enteries={data.length}
                totalCount={totalCount}
              />
            </div>
          </div>
            </div>
        </DashboardLayout>
    )
}

export default ManageRoles
import './App.css';
import "bootstrap/dist/css/bootstrap.css"
import AdminRouter from './Routers';
import "./Assets/css/style.css";

function App() {
  return (
    <AdminRouter />
  );
}

export default App;

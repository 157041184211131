/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 11/11/2023 - 22:32:27
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/11/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";
import { checkAudioFormat } from "../../Util/helpers";

import { placeholderImage } from "../../Assets/images";

import "./style.css";
import BASEURL from "../../Config/global";

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();




  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [featuredState, setFeaturedState] = useState(false);
  const [article, setArticle] = useState(null);
  const [articleError, setArticleError] = useState(false);

  const [articleImage, setArticleImage] = useState(null);
  const [articleThumbnail, setArticleThumbnail] = useState(null);

  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);

  const [formImgShow, setFormImgShow] = useState({});

  const [pri_header_logo_info, set_pri_header_logo_info] = useState('');
  const [pri_mobile_logo, set_pri_mobile_logo] = useState('');
  const [pri_desktop_logo, set_pri_desktop_logo] = useState('');
  const [sec_header_logo_info, set_sec_header_logo_info] = useState('');
  const [sec_mobile_logo, set_sec_mobile_logo] = useState('');
  const [sec_desktop_logo, set_sec_desktop_logo] = useState('');


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        console.log(response.data)
        setFormData(response.data.data[0]);
        set_pri_header_logo_info(response.data.data[0]['pri_header_logo_info']);
        set_pri_mobile_logo(response.data.data[0]['pri_mobile_logo']);
        set_pri_desktop_logo(response.data.data[0]['pri_desktop_logo']);
        set_sec_header_logo_info(response.data.data[0]['sec_header_logo_info']);
        set_sec_mobile_logo(response.data.data[0]['sec_mobile_logo']);
        set_sec_desktop_logo(response.data.data[0]['sec_desktop_logo']);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  console.log(formData)


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData)
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const formDataMethod = new FormData();
    // for (const key in formData) {
    //   if (formData[key] != null) {
    //     formDataMethod.append(key, formData[key]);
    //   }
    // }

    formDataMethod.append("title", formData.title);
    formDataMethod.append("price", formData.price);
    formDataMethod.append("interval", formData.interval);
    formDataMethod.append("description", formData.description);

    // HTML info tray
    formDataMethod.append("pri_company_title", formData.pri_company_title);
    formDataMethod.append("pri_html_info_text", formData.pri_html_info_text);
    formDataMethod.append("sec_company_title", formData.sec_company_title);
    formDataMethod.append("sec_html_info_text", formData.sec_html_info_text);
    (formImgShow.pri_header_logo_info != undefined && formImgShow.pri_header_logo_info != null) && formDataMethod.append("pri_header_logo_info", formImgShow.pri_header_logo_info);
    (formImgShow.pri_mobile_logo != undefined && formImgShow.pri_mobile_logo != null) && formDataMethod.append("pri_mobile_logo", formImgShow.pri_mobile_logo);
    (formImgShow.pri_desktop_logo != undefined && formImgShow.pri_desktop_logo != null) && formDataMethod.append("pri_desktop_logo", formImgShow.pri_desktop_logo);
    (formImgShow.sec_header_logo_info != undefined && formImgShow.sec_header_logo_info != null) && formDataMethod.append("sec_header_logo_info", formImgShow.sec_header_logo_info);
    (formImgShow.sec_mobile_logo != undefined && formImgShow.sec_mobile_logo != null) && formDataMethod.append("sec_mobile_logo", formImgShow.sec_mobile_logo);
    (formImgShow.sec_desktop_logo != undefined && formImgShow.sec_desktop_logo != null) && formDataMethod.append("sec_desktop_logo", formImgShow.sec_desktop_logo);


    // // HTML info tray
    // formDataToSend.append("pri_company_title", formData.pri_company_title);
    // formDataToSend.append("pri_html_info_text", formData.pri_html_info_text);
    // formDataToSend.append("sec_company_title", formData.sec_company_title);
    // formDataToSend.append("sec_html_info_text", formData.sec_html_info_text);
    // (formImgShow.pri_header_logo_info != undefined && formImgShow.pri_header_logo_info != null) && formDataToSend.append("pri_header_logo_info", formImgShow.pri_header_logo_info);
    // (formImgShow.pri_mobile_logo != undefined && formImgShow.pri_mobile_logo != null) && formDataToSend.append("pri_mobile_logo", formImgShow.pri_mobile_logo);
    // (formImgShow.pri_desktop_logo != undefined && formImgShow.pri_desktop_logo != null) && formDataToSend.append("pri_desktop_logo", formImgShow.pri_desktop_logo);
    // (formImgShow.sec_header_logo_info != undefined && formImgShow.sec_header_logo_info != null) && formDataToSend.append("sec_header_logo_info", formImgShow.sec_header_logo_info);
    // (formImgShow.sec_mobile_logo != undefined && formImgShow.sec_mobile_logo != null) && formDataToSend.append("sec_mobile_logo", formImgShow.sec_mobile_logo);
    // (formImgShow.sec_desktop_logo != undefined && formImgShow.sec_desktop_logo != null) && formDataToSend.append("sec_desktop_logo", formImgShow.sec_desktop_logo);



    setLoader(true);
    // Call the async function to initiate the POST request.
    postData();

    async function postData() {
      try {
        const response = await axios.patch(
          `${BASEURL}/api/user/packages/${id}`,
          formDataMethod,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.error === false) {
          successModal.fire({
            text: "Subscription Edited Successfully",
            confirmButtonText: "Continue",
          });
          setLoader(false);
          navigate("/subscription-management");
        } else {
          setLoader(false);
        }
        // Handle the response data here, e.g., log it or use it in your application.
        console.log("Response data:", response.data);
      } catch (error) {
        // Handle errors here
        console.error("An error occurred:", error);

        // You can also provide specific error messages or actions based on the error type:
        if (error.response) {
          // The request was made, but the server responded with an error.
          console.error("Server error:", error.response.data);
        } else if (error.request) {
          // The request was made, but there was no response from the server.
          console.error("No response received from the server.");
        } else {
          // Something else went wrong.
          console.error("An unexpected error occurred:", error.message);
        }
      }
    }






  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // setFormData({ ...formData, [event.target.name]: file });
    setFormData({ ...formData, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // setSoundImage(reader.result);
        // const { name, value } = event.target;
        setFormImgShow({ ...formImgShow, [event.target.name]: reader.result });
        // form.pri_header_logo_info(reader.result)
        // console.log(formData)
      };
    }
  }


  const priHeaderInfoImg = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_pri_header_logo_info(reader.result);
      };
    }
  }

  const priMobileLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_pri_mobile_logo(reader.result);
      };
    }
  }

  const priDesktopLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_pri_desktop_logo(reader.result);
      };
    }
  }

  const secHeaderInfoImg = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_sec_header_logo_info(reader.result);
      };
    }
  }

  const secMobileLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_sec_mobile_logo(reader.result);
      };
    }
  }

  const secDesktopLogo = (event) => {
    const file = event.target.files[0];
    setFormImgShow({ ...formImgShow, [event.target.name]: file });
    if (file === null || file === "undefined") {
      setFormImgShow({ ...formImgShow, [event.target.name]: null });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        set_sec_desktop_logo(reader.result);
      };
    }
  }




  console.log(sec_desktop_logo)

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Edit Subscription
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">

                  <div className="col-lg-4 mb-2">
                    <CustomInput
                      label="Title"
                      labelClass="mainLabel"
                      required
                      type="text"
                      name="title"
                      value={formData.title || ""}
                      placeholder="Enter Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-4 mb-2">
                    <CustomInput
                      label="Price"
                      labelClass="mainLabel"
                      required
                      type="number"
                      name="price"
                      value={formData.price || ""}
                      placeholder="Enter Price"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-4 mb-2">
                    <p className="mainLabel mb-4">Subscription Type*</p>
                    <label className="secondaryText me-3">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"month"}
                        className="me-1"
                        checked={formData?.interval == "month" ? true : false}
                        onChange={handleChange}
                      />
                      Monthly
                    </label>
                    <label className="secondaryText">
                      <input
                        type="radio"
                        name="interval"
                        required
                        value={"year"}
                        className="me-1"
                        checked={formData?.interval == "year" ? true : false}
                        onChange={handleChange}
                      />
                      Yearly
                    </label>
                  </div>

                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">Description*</label>
                      <textarea
                        rows="5"
                        placeholder="Enter Description"
                        required=""
                        name="description"
                        className="mainInput"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>




                  {/* Primary User */}
                  <div className="col-12 my-5">
                    <h4 className="fw-bold">Primary User</h4>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Company Title"
                      labelClass="mainLabel mb-3"
                      type="text"
                      name="pri_company_title"
                      value={formData?.pri_company_title || ""}
                      placeholder="Enter Company Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Header Information Image</p>
                      <div className="imageInput couponImageInput">
                        {pri_header_logo_info ? (
                          <img src={pri_header_logo_info} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="pri_header_logo_info"
                        onChange={priHeaderInfoImg}
                      />
                    </label>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Mobile Image</p>
                      <div className="imageInput couponImageInput">
                        {pri_mobile_logo ? (
                          <img src={pri_mobile_logo} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="pri_mobile_logo"
                        onChange={priMobileLogo}
                      />
                    </label>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Desktop Image</p>
                      <div className="imageInput couponImageInput">
                        {pri_desktop_logo ? (
                          <img src={pri_desktop_logo} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="pri_desktop_logo"
                        onChange={priDesktopLogo}
                      />
                    </label>
                  </div>
                  {/* <div className="col-lg-12">
                <div className="App" >
                  <label class="mainLabel">HTML Information</label>
                  <Editor
                    // editorState={priEditorState}
                    // onEditorStateChange={newEditorState => setPriEditorState(newEditorState)}
                    editorState={priEditorState}
                    onEditorStateChange={newEditorState => setPriEditorState(newEditorState)}            
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ['inline', 'colorPicker', 'link', 'embedded', 'image', 'blockType', 'fontSize', 'list', 'textAlign', 'remove'],
                      link: {
                        showOpenOptionOnHover: true,
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                    }}
                  />


                </div>
              </div> */}
                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">HTML Information</label>
                      <textarea
                        rows="10"
                        placeholder=""
                        required=""
                        name="pri_html_info_text"
                        className="mainInput"
                        value={formData.pri_html_info_text || ""}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>



                  {/* Secondary User */}
                  <div className="col-12 my-5">
                    <h4 className="fw-bold">Household User</h4>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <CustomInput
                      label="Company Title"
                      labelClass="mainLabel mb-3"
                      type="text"
                      name="sec_company_title"
                      value={formData?.sec_company_title || ""}
                      placeholder="Enter Company Title"
                      inputClass="mainInput"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Header Information Image</p>
                      <div className="imageInput couponImageInput">
                        {sec_header_logo_info ? (
                          <img src={sec_header_logo_info} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="sec_header_logo_info"
                        onChange={secHeaderInfoImg}
                      />
                    </label>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Mobile Image</p>
                      <div className="imageInput couponImageInput">
                        {sec_mobile_logo ? (
                          <img src={sec_mobile_logo} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="sec_mobile_logo"
                        onChange={secMobileLogo}
                      />
                    </label>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label className="w-100">
                      <p className="mainLabel">Desktop Image</p>
                      <div className="imageInput couponImageInput">
                        {sec_desktop_logo ? (
                          <img src={sec_desktop_logo} className="img" alt="Main" />
                        ) : (
                          <img src={placeholderImage} alt="Main" />
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        className="d-none"
                        name="sec_desktop_logo"
                        onChange={secDesktopLogo}
                      />
                    </label>
                  </div>
                  {/* <div className="col-lg-12">
                <div className="App" >
                  <label class="mainLabel">HTML Information</label>
                  <Editor
                    editorState={secEditorState}
                    onEditorStateChange={setSecEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ['inline', 'colorPicker', 'link', 'embedded', 'image', 'blockType', 'fontSize', 'list', 'textAlign', 'remove'],
                      link: {
                        showOpenOptionOnHover: true,
                      },
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                    }}
                  />


                </div>
              </div> */}
                  <div className="col-lg-12 mb-2">
                    <div className="inputWrapper">
                      <label className="mainLabel">HTML Information</label>
                      <textarea
                        rows="10"
                        placeholder=""
                        required=""
                        name="sec_html_info_text"
                        className="mainInput"
                        value={formData.sec_html_info_text || ""}
                        onChange={handleChange}
                      ></textarea></div>
                  </div>









                </div>
                {/* {loginError.error == true && (
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="secondaryText">{loginError.text}</p>
                    </div>
                  </div>
                )} */}
                <div className="row">
                  <div className="col-12">
                    <CustomButton
                      type="submit"
                      variant="primaryButton"
                      text="Update"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default EditSubscription;

import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import axios from "axios";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomLoader from "../../Components/CustomLoader";
import { checkAudioFormat } from "../../Util/helpers";

import { placeholderImage } from "../../Assets/images";

import "./style.css";
import BASEURL from "../../Config/global";

const SubscriptionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const primaryInformation = useRef(null)
  const secondaryInformation = useRef(null)




  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [featuredState, setFeaturedState] = useState(false);
  const [article, setArticle] = useState(null);
  const [articleError, setArticleError] = useState(false);

  const [articleImage, setArticleImage] = useState(null);
  const [articleThumbnail, setArticleThumbnail] = useState(null);

  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        setFormData(response.data.data[0]);
        console.log(response.data)
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (primaryInformation?.current != null) {
    primaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    primaryInformation?.current.appendChild(div);
    console.log(primaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.pri_html_info_text)
  }

  if (secondaryInformation?.current != null) {
    secondaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    secondaryInformation?.current.appendChild(div);
    console.log(secondaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.sec_html_info_text)
  }








  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Subscription Detail
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-lg-6 mb-2">
                  <p className="text-bold">Title:</p>
                  <p>{formData?.title}</p>
                </div>
                <div className="col-lg-6 mb-2">
                  <p className="text-bold">Price:</p>
                  <p>{formData?.price}</p>
                </div>
                <div className="col-lg-12 mb-2">
                  <p className="text-bold">Description:</p>
                  <p>{formData?.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5">
              <h4 className="fw-bold">Primary User</h4>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Company Title</p>
              <p>{formData?.pri_company_title}</p>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Header Information Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_header_logo_info ? <img className="img" src={formData?.pri_header_logo_info} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Mobile Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_mobile_logo ? <img className="img" src={formData?.pri_mobile_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Desktop Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_desktop_logo ? <img className="img" src={formData?.pri_desktop_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <p className="text-bold lightColor">Information Tray</p>
              <div ref={primaryInformation}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5">
              <h4 className="fw-bold">Household User</h4>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Company Title</p>
              <p>{formData?.sec_company_title}</p>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Header Information Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_header_logo_info ? <img className="img" src={formData?.sec_header_logo_info} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Mobile Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_mobile_logo ? <img className="img" src={formData?.sec_mobile_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Desktop Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_desktop_logo ? <img className="img" src={formData?.sec_desktop_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <p className="text-bold lightColor">Information Tray</p>
              <div ref={secondaryInformation}></div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default SubscriptionDetails;

import './custom-modal.css'
import React, { useEffect, useState } from 'react';
import { Accordion, Form, ListGroup } from 'react-bootstrap';
import { permissionList } from '../../Config/PermissionData';

const flatToNested = (flatArray, parentId = null) => {
    const nestedArray = [];
    flatArray.forEach(item => {
        if (item.parent_id === parentId) {
            const newItem = { ...item };
            const children = flatToNested(flatArray, item.id);
            if (children.length) {
                newItem.sub_permissions = children;
            }
            nestedArray.push(newItem);
        }
    });
    return nestedArray;
};

const BaseConditions = ({name, value, onChange}) => {

    return (
        <ListGroup.Item className='customList'>
            <Form.Check
                type="checkbox"
                label={name}
                id={`checkbox-${name}`}
                className="me-2"
                checked={value}
                onChange={onChange}
            />
        </ListGroup.Item>
    )
}
const areAllPermissionsTrue = (permission) => {
    if (!permission) {
        return false
    }
    return Object.keys(permission)
        .filter((key) => key.startsWith('can_'))
        .every(key => permission[key] === true);
};
const AccordionItem = ({ parenId, permission, setParentOpen, setChildOpen, parentOpen, childOpen, childId, handlePermissionChangeMethod, handleAllSelect }) => {

    const { sub_permissions } = permission;
    const toggleAccordion = () => {
        // if (setParentOpen) {
        //     setParentOpen(parenId);
        // } else if (setChildOpen) {
        //    setChildOpen(childId);
        // } 
    };
    const child = [], parents = []
    for (let i = 0; i < sub_permissions?.length; i++) {
        const current = sub_permissions[i];
        if (!current.sub_permissions && current.parent_id) {
            child.push(current)
        } else {
            parents.push(current)
        }
    }

    console.log("areAllPermissionsTrue", areAllPermissionsTrue())
    return (
        <div className='alignInput'>
            <div className='customCheckBox'>
                <Form.Check
                    type="checkbox"
                    label={permission.module_name}
                    id={`checkbox-${permission.module_name}-main`}
                    // className="me-2"

                    checked={areAllPermissionsTrue(permission) ? true : false}
                    onChange={() => handleAllSelect(permission)}
                />
            </div>

            <Accordion
                className='swap-modal-for-permission '
                defaultActiveKey={1}
            >
                <Accordion.Item
                    className='customAccordion'
                    key={`Accordion-${permission?.id?.toString()}`}
                    eventKey={permission.id}
                >
                    <Accordion.Header
                        // className='stop-header-from-closing'
                        onClick={toggleAccordion}
                    >
                    </Accordion.Header>
                    <Accordion.Body className='customAccordion'>
                        <ListGroup className='customList'>
                            {Object.entries(permission)
                                .filter(([key]) => key.startsWith('can_'))
                                .map(([key, value]) => <BaseConditions name={key} value={value} onChange={(event) => handlePermissionChangeMethod(permission.module_name, key, value)} />)}
                        </ListGroup>


                        {sub_permissions?.length > 0 && (
                            sub_permissions.map((sub_permission, index2) => {
                                return (
                                    <AccordionItem
                                        key={sub_permission.id}
                                        childId={sub_permission.id}
                                        permission={sub_permission}
                                    // setChildOpen={setChildOpen}
                                    // childOpen={childOpen}
                                    />
                                )
                            })
                        )}

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}


const PermissionDetails = ({ selectedPermissions, setSelectedPermissions }) => {
    const [nestedPermissionList, setNestedPermissionList] = useState();
    const [parentOpen, setParentOpen] = useState(1);
    const [childOpen, setChildOpen] = useState(null);

    const handlePermissionChangeMethod = (module_name, key, value) => {
        let data = []
        for (let i = 0; i < selectedPermissions.length; i++) {
            const current = selectedPermissions[i];
            if (current.module_name === module_name) {
                data.push({ ...current, [key]: !(JSON.parse(value)) })
            } else {
                data.push(current)
            }
        }
        setSelectedPermissions(data)
    }
    useEffect(() => {
        setNestedPermissionList(selectedPermissions)
    }, [selectedPermissions])
    if (!nestedPermissionList) {
        return null
    }
    const handleAllSelect = (permission) => {
        let data = []
        for (let i = 0; i < selectedPermissions.length; i++) {
            const current = selectedPermissions[i];
            if (current.module_name === permission.module_name) {
                const checkType = areAllPermissionsTrue(permission)
                let temp = {}
                let keys = Object.keys(current)
                for (let i = 0; i < keys.length; i++) {
                    const currentKey = keys[i]
                    if (currentKey.startsWith('can_')) {
                        temp[currentKey] = !checkType
                    }
                }
                data.push({ module_name: permission.module_name, ...temp })
            } else {
                data.push(current)
            }
        }
        setSelectedPermissions(data)
    }
    return nestedPermissionList.map((permission, index) => {
        return (
            <AccordionItem
                handlePermissionChangeMethod={handlePermissionChangeMethod}
                key={permission.id}
                permission={permission}
                parenId={permission.id}
                setParentOpen={setParentOpen}
                setChildOpen={setChildOpen}
                parentOpen={parentOpen}
                childOpen={childOpen}
                handleAllSelect={handleAllSelect}
            />
        )
    })
};

export default PermissionDetails;



import { useRef, useState } from "react";
import { useParams, useNavigate } from "react-router";


import { questionModal, successModal } from "../../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from 'react-router-dom';

import { DashboardLayout } from "../../../Layout/DashboardLayout";
import BackButton from "../../../Components/BackButton";
import CustomButton from "../../../Components/CustomButton";

import "../style.css";
import BASEURL from "../../../Config/global";
import { useEffect } from "react";
import SubCategoryComponent from "../SubCategoryComponent";
import { Modal } from "react-bootstrap";
import CustomInput from "../../../Components/CustomInput";
import CustomFilters from "../../../Components/CustomFilters";
import axios from "axios";
import { AddImageComponent } from "../../CategoryManagement/ImageComponent";

const AddStorySubCategory = () => {
    const [searchParams] = useSearchParams();
    const [editId, setEditId] = useState();


    const [inputValue, setinputValue] = useState("");
    const [subCategories, setSubCategories] = useState([])
    const [showSwapModal, setShowSwapModal] = useState(false);
    const [swapInputError, setInputError] = useState({ error: true, text: '' });
    const [categoryData, setCategoryData] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState();
    const [soundFilterSearchValue, setSoundFilterSearchValue] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [soundImage, setSoundImage] = useState(null);
  
    useEffect(() => {
        fetchStory()
    }, []);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        if (file === null || file === "undefined" || file === undefined) {
          setSoundImage(null);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            setSoundImage(reader.result);
            setSelectedImage(reader.result);
    
            console.log(reader)
          };
        }
      };
      const handleRemoveImage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setSoundImage(null)
        setSelectedImage(null)
      }
    useEffect(() => {
        getExistingStorySubCategories(selectedTrack)
    }, [selectedTrack]);

    const fetchStory = async () => {
        try {
            const response = await axios.get(
                `${BASEURL}/api/stories/storiescategories`
            );
            if (response.data.data) {
                const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
                setCategoryData(sortedData);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getExistingStorySubCategories = async (trackId) => {
        if (!trackId) {
            return
        }
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`${BASEURL}/api/stories/storiescategories/${trackId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    const { category_data } = result?.data[0]
                    const sortedData = category_data.sort(function (a, b) { return a.sequence - b.sequence })
                    setSubCategories(sortedData)
                }
            })
            .catch((error) => console.error(error));
    }
    const postData = async (updatedCategories, method) => {
        try {
            if (!selectedTrack && updatedCategories && method) {
                return
            }
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "id": selectedTrack,
                "category_data": updatedCategories
            });
            const requestOptions = {
                method: method,
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            fetch(`${BASEURL}/api/stories/storiescategories`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log("response---------->", result)
                })
                .catch((error) => console.error(error))
        } catch (error) {
            console.error(error);
        }
    };



    const addCategory = (event) => {
        if (inputValue !== "") {
            questionModal
                .fire({
                    title: `Do you want to ${editId ? "update" : "add"} this sub category?`,
                    confirmButtonText: editId ? "Update" : "Add",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        postData([{ id: editId ? editId : subCategories.length + 1, name: inputValue, banner: selectedImage }], editId ? "PATCH" : "POST").then((result) => {
                            setinputValue("")
                            setEditId("")
                            setShowSwapModal(false)
                            if (editId) {
                                const findEdit = subCategories.findIndex(f => f.id === editId)
                                let prevData = [...subCategories]
                                prevData[findEdit] = { ...prevData[findEdit], name: inputValue, banner: selectedImage }
                                setSubCategories(prevData)
                            }
                            else {
                                setSubCategories((sub) => [...sub, { id: subCategories.length + 1, name: inputValue, banner: selectedImage, sound_count: 0 }])
                            }
                            successModal.fire({
                                text: `Sub category ${editId ? "Updated" : "Added"}  Successfully`,
                                confirmButtonText: "Continue",
                            });
                            setSelectedImage(null)
                            setSoundImage(null)
                        })

                    }
                });
        }
        else {
            setInputError({ error: true, 'text': 'Sub category title cannot be empty' })
        }
    }

    const handleEditItemCallBack = (data) => {
        setinputValue(data.name)
        setSelectedImage(data.banner)
        setSoundImage(data.banner)
        setEditId(data.id)
        setShowSwapModal(true)
    }
    const handleDeleteItemCallBack = (item) => {
        questionModal
            .fire({
                title: "Do you want to delete this sub category",
                confirmButtonText: "Delete",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const requestOptions = {
                        method: "DELETE",
                        redirect: "follow"
                    };
                    fetch(`${BASEURL}/api/stories/storiescategories/${item}`, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            successModal.fire({
                                text: "Sub category deleted Successfully",
                                confirmButtonText: "Continue",
                            });
                            setSubCategories([...subCategories.filter(subcategory => subcategory.id !== item)]);
                        })
                        .catch((error) => console.error(error));

                }
            });
    }
    const setSelectedTrackHandler = (event) => {
        setSelectedTrack(event.target.value)
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 mb-2 ">
                    <div className="d-flex flex-row align-items-center">
                        <p className="mainLabel mt-2">Select Category*</p>
                        {categoryData && (
                            <select
                                name="category"
                                id="category"
                                className="mainInput w-auto"
                                required
                                value={selectedTrack}
                                onChange={setSelectedTrackHandler}
                            >
                                <option value={"undefined"}>{"Select Category"}</option>
                                {categoryData.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
                <div className="col-3 ms-auto text-end">
                    <button
                        disabled={!selectedTrack ? true : false}
                        onClick={() => {
                            setEditId("")
                            setinputValue("")
                            setShowSwapModal(true)
                            setInputError({ error: false, text: '' })
                            setSelectedImage(null)
                            setSoundImage(null)
                        }}
                        className={`customButton ${!selectedTrack ? '' : 'primaryButton'}`}
                    >
                        Add
                    </button>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 ">
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomFilters
                                enteries={subCategories.length}
                                totalCount={subCategories.length}
                                entriesFilter={true}
                                setEnteries={() => { }}
                                filterSearch={true}
                                filterSearchValue={soundFilterSearchValue}
                                setFilterSearchValue={setSoundFilterSearchValue}
                            // dateFilter={true}

                            />
                        </div>
                    </div>
                </div>


            </div>
            <div className="row mb-3">
                <div className="col-12">
                    {
                        <SubCategoryComponent
                            data={subCategories}
                            handleDeleteItemCallBack={handleDeleteItemCallBack}
                            handleEditItemCallBack={handleEditItemCallBack}

                        />
                    }
                </div>
                <Modal
                    show={showSwapModal}
                    centered
                    className="swap-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="pb-5 w-100 d-flex flex-column align-items-center">
                            <div className="d-flex justify-content-between w-100 px-3">
                                <h2 className="mainTitle">{editId ? "Edit" : "Add"} Sub Category</h2>
                                <div className="text-end ms-auto">
                                    <button
                                        className="closeButton notButton ms-auto"
                                        onClick={() => {
                                            setEditId("")
                                            setinputValue("")
                                            setShowSwapModal(false)
                                            setInputError({ error: false, text: '' })

                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className="cross-icon"
                                            color="white"
                                        />
                                    </button>
                                </div>
                            </div>
                           <div className="d-flex flex-column align-items-start w-100 mt-3">
                               <div className="w-100 px-3 mx-1" style={{}}>
                               <CustomInput
                                    label="Title"
                                    labelClass="mainLabel"
                                    required
                                    type="text"
                                    name="title"
                                    value={inputValue}
                                    placeholder="Enter Sub Category"
                                    inputClass="mainInput"
                                    onChange={(ev) => {
                                        setinputValue(ev.target.value)
                                        setInputError({ error: false, text: '' })
                                    }}
                                />
                               </div>
                               <AddImageComponent
                                    soundImage={soundImage}
                                    handleImageChange={handleImageChange}
                                    handleRemoveImage={handleRemoveImage}
                                    inputClass="w-100 px-3 modal-image"
                                />
                            </div>
                        
                            {swapInputError?.error && <small className="text-danger mt-3">{swapInputError.text}</small>}
                            <div className=" pt-3 d-flex gap-3 justify-content-center">
                                <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={addCategory}>
                                    <span className=''>{editId ? "UPDATE" : "Add"}</span>
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </div>
    );
};
export default AddStorySubCategory;
import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Link } from 'react-router-dom'
import CustomFilters from '../../Components/CustomFilters'
import CustomTable from '../../Components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import CustomPagination from '../../Components/CustomPagination'
import { Dropdown } from 'react-bootstrap'
import { PermissionsContext } from '../../Layout/PermissionContext'
import EditUserModal from './EditUserModal'
import { exampleRole, testUserRoles } from '../../Config/PermissionData'
import axios from 'axios'
import BASEURL from '../../Config/global'
import CustomLoader from '../../Components/CustomLoader'
import { questionModal, successModal } from '../../Components/CustomModal'
import { disabledButtonByPermission } from '../../Components/Permissions'

// const ExampleComponent = () => {

//   const hasPermission = permissions.includes('write');

//   return (
//     <div>
//       {hasPermission && (
//         <button>Write Action</button>
//       )}
//     </div>
//   );
// };

const UserRoles = () => {
  const { permissions } = useContext(PermissionsContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [allRoles, setAllRoles] = useState();

  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [sort, setSort] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [enteries, setEnteries] = useState(10);
  const [editUserModal, setEditUserModal] = useState(false)
  const [editUser, setEditUser] = useState()

  console.log("permissions", permissions)

  const handleEditModalClose = () => {
    setEditUser()
    setEditUserModal(false);
  };

  const modulesWisePermission = (condition) =>{
    if(!permissions){
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  const rolesHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "role",
      title: "Role",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];
  useEffect(() => {
    getAllRoles()
    getAllEmployes()
  }, [])
  useEffect(() => {
    async function fetchData() {
      try {
        // setData(testUserRoles.filter(f => f.role.toLowerCase().includes(filterSearchValue.trim())))
      } catch (error) {
        console.error(error);
      }
    }
   
    fetchData();
  }, [enteries, filterSearchValue, dateTo]);
  const handleEditUserRolesModal = (item) => {
    setEditUser(item)
    setEditUserModal(true)
  }
  const saveAndUpdateRole = (updatedData) => {
    const index = data.findIndex(f => f.id === updatedData.id)
    data[index] = { ...updatedData };
    setData(data)
    setEditUserModal(false)
    setEditUser()
  }
  const getAllEmployes = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BASEURL + '/api/permissions/all/users',
      headers: {}
    };

    await axios.request(config)
      .then((response) => {
        const { data } = response.data;
        setData(data)
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const getAllRoles = async() => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/api/permissions/userrolelist/`,
      headers: {}
    };

    await axios.request(config)
      .then((response) => {
        const { data } = response.data;
        setAllRoles(data)
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const handleSubmit = (deleteUserId) => {
    // event.preventDefault();
    questionModal
      .fire({
        title: "Do you want to delete this user?",
        confirmButtonText: "Delete",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await handleDeleteUser(deleteUserId);
        }
      });
  };
  const handleDeleteUser = async (deleteUserId) => {

    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: BASEURL + '/api/permissions/delete/user/' + deleteUserId,
      headers: {}
    };
    await axios.request(config)
      .then((response) => {
        successModal.fire({
          text: "Role deleted successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter(f => f.id !== deleteUserId))
        console.log(JSON.stringify(response.data));

      })
      .catch((error) => {
        setLoadingData(false)
        console.log(error);
      });

  }
   
 
  if (!data) {
    return <CustomLoader />
  }
  console.log("modulesWisePermission",modulesWisePermission('can_add'), {...permissions})
  return (
    <DashboardLayout>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-lg-8 mb-2">
            <h2 className="mainTitle">Employees</h2>
          </div>
          <div className="col-lg-4 text-end mb-2">
            <Link
              to={"/employee-management/add-user"}
              className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
            >
              Add Employee
            </Link>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <CustomFilters
              enteries={data.length}
              totalCount={totalCount}
              entriesFilter={true}
              setEnteries={setEnteries}
              filterSearch={true}
              filterSearchValue={filterSearchValue}
              setFilterSearchValue={setFilterSearchValue}
              // dateFilter={true}
              setDateFrom={setDateFrom}
              setDateTo={setDateTo}
            // isSearchDropdown={couponsHeader}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
                <CustomTable headers={rolesHeader}>
                  <tbody>
                    {data &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.user}</td>

                      <td className='px-3'>{item.role}</td>
                      <td>
                        <Dropdown className="tableDropdown">
                          <Dropdown.Toggle
                            variant="transparent"
                            className="notButton classicToggle"
                          >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            align="end"
                            className="tableDropdownMenu"
                          >
                            <div
                              onClick={() => handleEditUserRolesModal(item)}
                              className="tableAction"
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="tableActionIcon"
                              />
                              Change Role
                            </div>
                                <Link
                                  to={`/employee-management/add-user/?userId=${item.id}`}
                                  className={`tableAction ${modulesWisePermission('can_edit')}`}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="tableActionIcon"
                                  />
                                  Edit User info
                                </Link>                                
                                <button
                                  className={`tableAction ${modulesWisePermission('can_delete')}`}
                                  onClick={() => {
                                    handleSubmit(item.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="tableActionIcon"
                                  />
                                  Delete
                                </button>
                              </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </CustomTable>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <CustomPagination
              enteries={data.length}
              totalCount={totalCount}
            />
          </div>
        </div>
        {
          editUserModal &&
          <EditUserModal
            editUser={editUser}
            roles={allRoles}
            show={editUserModal}
            saveAndUpdateRole={saveAndUpdateRole}
            onHide={handleEditModalClose}
          />
        }
      </div>

    </DashboardLayout>
  )
}

export default UserRoles



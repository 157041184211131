import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation hook from React Router
import UnauthorizedResourceError from '../../Screens/UnauthorizedResourceError/UnauthorizedResourceError';
// import { permissionList } from '../../Config/PermissionData'
export const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => { 
     
    const permissionList = window.localStorage.getItem('permissionList') ? JSON.parse(window.localStorage.getItem('permissionList')) : null
    const [permissions, setPermissions] = useState();
    const location = useLocation();
    const navigate = useNavigate()
    const setPermissionsData = (newPermissions) => {
        setPermissions(newPermissions);
    };


    const filterPermissionsByRoute = async(routeName) => {
        if (!routeName) {
            return;
        }
        const initialRouteName = routeName.split("/")[1]
        if (!initialRouteName) {
            return;
        }
        const soundManagement = permissionList.find(f => f.module_name.split(' ').join("-").toLowerCase() === initialRouteName)
        return await soundManagement;
    };

    const updatePermissionsByRoute = async() => {
        const filteredPermissions = await filterPermissionsByRoute(location.pathname);
        setPermissions(filteredPermissions);
    };

    useEffect(() => {
        if(permissionList)
        updatePermissionsByRoute();
    }, [location]);
    useEffect(() => {
        if(!permissions){
            return
        }
        if (!permissions['can_access']) {
            navigate("/unautherized-acess", { replace: true });
            return
        }
    }, [permissions, location])
    return (
        <PermissionsContext.Provider value={{ permissions, setPermissions: setPermissionsData }}>
            {children}
        </PermissionsContext.Provider>
    );
};




import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CustomInput from '../../Components/CustomInput'
import BASEURL from '../../Config/global'
import axios from 'axios'

const EditUserModal = ({ editUser, show, onHide, roles, saveAndUpdateRole }) => {
    const [role, setRole] = useState()
    const [allRoles, setAllRoles] = useState();

    const handleChangeRole = (event) => [
        setRole(event.target.value)
    ]
    useEffect(() => {
        if (editUser?.role) {
            setRole(editUser?.role)
        }
    }, [editUser])
    const handleSaveAndUpdate = () => {
        let updatedValues = { ...editUser, role }
        saveAndUpdateRole(updatedValues)
    }
    const getAllRoles = async() => {

        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${BASEURL}/api/permissions/userrolelist/`,
          headers: {}
        };
    
        await axios.request(config)
          .then((response) => {
            const { data } = response.data;
            setAllRoles(data)
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
    
      }
    return (
        <Modal
            dialogClassName='swap-modal-for-permission custom-modal'
            show={show}
            onHide={onHide}
            // size="lg"
            centered
        >
            <Modal.Header
                style={{ border: 'none' }}
                closeButton
            >
                <Modal.Title className='mx-3'>Change User Role</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ border: 'none' }}
            >
                <div className='row'>
                    <div className='col-11 mx-auto'>
                        <CustomInput
                            label="Title"
                            required
                            id="name"
                            type="text"
                            labelClass="mainLabel"
                            inputClass="mainInput"
                            name="name"
                            disabled
                            value={editUser?.user?.toUpperCase() || ""}
                        //   onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-11 mx-auto'>
                        <p className="mainLabel">Select User Role*</p>

                        <select
                            name="category"
                            id="category"
                            className="mainInput"
                            required
                            value={role || ""}
                            onChange={handleChangeRole}
                        >
                            {roles?.length > 0 && roles.map((item, index) => (
                                <option key={index} value={item.title}>
                                    {item?.name?.toUpperCase()}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{ border: 'none' }}
            >
                <div className='w-100 d-flex align-items-center justify-content-center mx-5 gap-4'
                >
                    <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={handleSaveAndUpdate}>
                        Save Changes
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditUserModal
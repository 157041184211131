/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 11/11/2023 - 23:25:47
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 11/11/2023
    * - Author          : Saif
    * - Modification    : 
**/
import "./style.css";


const CustomInput = (props) => {

  return (
    <>
      <div className="inputWrapper">
        {props?.label && (
          <label htmlFor={props?.id} className={props?.labelClass}>
            {props?.label}
            {props?.required ? "*" : ""}
          </label>
        )}
        <input
          type={props?.type}
          placeholder={props?.placeholder}
          required={props?.required}
          id={props?.id}
          value={props?.value}
          name={props?.name}
          className={props?.inputClass}
          onChange={props?.onChange}
          disabled={props?.disabled}
          min={props?.min}
          max={props?.max}
        />
      </div>
    </>
  );
};
export default CustomInput;

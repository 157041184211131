import { Pagination } from "react-bootstrap";

import "./style.css";
import { useState } from "react";

const CustomPagination = ({ enteries, totalCount, currentPage }) => {
    const [active, setActive] = useState(1);
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
            // <Pagination.Item onClick={() => { currentPage(number - 1 + '0'); setActive(number) }} key={number} active={number === active}>
            //     {number}
            // </Pagination.Item>,
        );
    }
    return (
        <>
            <div className="customPagination">
                <div className="row align-items-baseline">
                    <div className="col-lg-6">
                        {/* <p className="paginationText">{enteries} of {totalCount}</p> */}
                    </div>
                    <div className="col-lg-6">
                        <Pagination>
                            <Pagination.Prev />
                            {items}
                            <Pagination.Next />
                            {/* <Pagination.Prev onClick={() => { active > 1 && currentPage(active - 2 + '0'); setActive(active - 1) }} />
                            {items}
                            <Pagination.Next  onClick={() => { currentPage(active); setActive(active + 1) }} /> */}
                        </Pagination>
                    </div>
                </div>
            </div>
        </>)
}

export default CustomPagination
import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Link, useNavigate } from 'react-router-dom'
import BackButton from '../../Components/BackButton'
import CustomInput from '../../Components/CustomInput'
import PermissionDetails from '../../Components/PermissionDetail'
import BASEURL from '../../Config/global'
import axios from 'axios'
import CustomLoader from '../../Components/CustomLoader'
import { questionModal, successModal } from '../../Components/CustomModal'

const AddRoles = () => {
    const [selectedPermissions, setSelectedPermissions] = useState();
    const [roleName, setRoleName] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState({ error: false, text: "" });

    useEffect(() => {
        getAllPermissions()
    }, [])
    const handeAddPermissions = async () => {

        let data = JSON.stringify({
            "name": roleName,
            "permissions": selectedPermissions
        });
        let config = {
            method: 'post',
            url: `${BASEURL}/api/permissions/userrolelist/`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                successModal.fire({
                    text: "Role Added successfully",
                    confirmButtonText: "Continue",
                });
                navigate(-1)
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const getAllPermissions = async () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${BASEURL}/api/permissions/permissions`,
            headers: {
                'Accept': 'application/json, text/plain, */*'
            }
        };

        await axios.request(config)
            .then((response) => {
                const { data } = response.data;
                setSelectedPermissions(data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleSubmit = (id) => {
        if (roleName === "" || !roleName) {
            setError({ error: true, text: "Role name cannot be empty" });
            return;
        }
        questionModal
            .fire({
                title: "Do you want to add this role?",
                confirmButtonText: "Add",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await handeAddPermissions();
                }
            });
    };

    if (!selectedPermissions) {
        return <CustomLoader />
    }

    return (

        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">
                        <BackButton />
                        Role Management
                    </h2>
                </div>
                <div className="col-lg-4 text-end mb-2">
                    <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill' onClick={handleSubmit}>
                        save changes
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 m-2">
                    <CustomInput
                        label="Role Name"
                        required
                        id="roleName"
                        type="text"
                        labelClass="mainLabel"
                        inputClass="mainInput"
                        name="roleName"
                        value={roleName}
                        onChange={(e) => {
                            setError({ error: false, text: "" });
                            setRoleName(e.target.value)
                        }
                        }
                    />
                    {error.error && (
                        <div className="col-12">
                            <p className="smallText lightColor">{error.text}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='col-lg-6 m-2'>
            <PermissionDetails selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />
            </div>
        </DashboardLayout>
    )
}

export default AddRoles
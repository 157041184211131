import { useState, useEffect } from "react";
import {DashboardLayout} from './../../Layout/DashboardLayout'
import StatCard from "../../Components/StatsCard/index.js";
import { stats } from "../../Config/Data";
import BASEURL from "../../Config/global";
import "./style.css";
import axios from "axios";

export const Dashboard = () => {
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    document.title = 'Relax Scofa | Dashboard';
    postData();
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      postData();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);
  
  const postData = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/user-stats/`
      );
      setStatistics(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <div className="row">
                {statistics.map((stats, index) => (
                  <div className="col-md-6 mb-3" key={index}>
                    <StatCard item={stats} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

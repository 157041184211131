import { useState } from "react";
import { useParams, useNavigate } from "react-router";

import axios from "axios";

import Swal from "sweetalert2";
import { questionModal, successModal } from "../../Components/CustomModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

import "./style.css";
import BASEURL, { FE_BASE_URL } from "../../Config/global";
import { useEffect } from "react";
import { placeholderImage } from "../../Assets/images";
import CustomInput from "../../Components/CustomInput";
import CustomLoader from "../../Components/CustomLoader";

import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from "draft-js";
import { useRef } from "react";

const CouponDetail = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const primaryInformation = useRef(null)
  const secondaryInformation = useRef(null)



  const [inputValue, setinputValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [target, setTarget] = useState(false);

  const [formData, setFormData] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/user/coupon/${id}`
        );
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const postData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("code", formData.code);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("expires", formData.expires);
    formDataToSend.append("trial_period", formData.trial_period);
    setLoader(true)
    try {
      const response = await axios.patch(
        `${BASEURL}/api/user/coupon/${id}`,
        formDataToSend
      );
      console.log(response)
      if (!response.data.error) {
        successModal.fire({
          text: "Promo Updated Successfully",
          confirmButtonText: "Continue",
        });
        setLoginError({ error: false, text: "" });
        setLoader(false);
        navigate("/promo-management");
      } else {
        setLoginError({ error: true, text: response.data.message });
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.title && formData.code && formData.price && formData.expires && formData.trial_period) {
      questionModal
        .fire({
          title: "Do you want to update this Promo?",
          confirmButtonText: "Update",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postData();
          }
        });
    }
    else {
      setLoginError({ error: true, text: "Please Fill All the Requires Fields" });
    }
  };

  if (primaryInformation?.current != null) {
    primaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    primaryInformation?.current.appendChild(div);
    console.log(primaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.pri_html_info_text)
  }

  if (secondaryInformation?.current != null) {
    secondaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    secondaryInformation?.current.appendChild(div);
    console.log(secondaryInformation.current)
    div?.insertAdjacentHTML("beforebegin", formData?.sec_html_info_text)
  }


  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Promo Detail
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Title:</p>
              <p>{formData?.title}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Code:</p>
              <p>{formData?.code}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Description:</p>
              <p>{formData?.description}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Code Description:</p>
              <p>{formData?.code_description}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Promo Url:</p>
              <p>{`${FE_BASE_URL}/promo/${formData?.code}`}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Price:</p>
              <p>USD {formData?.price}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Expires:</p>
              <p>{(formData?.expires === "undefined" || formData?.expires === null) ? "Unlimited" : formData?.expires}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Trial Period</p>
              <p>{formData?.trial_period}</p>
            </div>
            <div className="col-lg-6 mb-2">
              <p className="text-bold">Subscription Type</p>
              <p>{formData?.type}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5">
              <h4 className="fw-bold">Primary User</h4>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Company Title</p>
              <p>{formData?.pri_company_title}</p>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Header Information Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_header_logo_info ? <img className="img" src={formData?.pri_header_logo_info} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Mobile Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_mobile_logo ? <img className="img" src={formData?.pri_mobile_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Desktop Image</p>
              <div className="imageInput couponImageInput">
                {formData?.pri_desktop_logo ? <img className="img" src={formData?.pri_desktop_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <p className="text-bold lightColor">Information Tray</p>
              <div ref={primaryInformation}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5">
              <h4 className="fw-bold">Household User</h4>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Company Title</p>
              <p>{formData?.sec_company_title}</p>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Header Information Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_header_logo_info ? <img className="img" src={formData?.sec_header_logo_info} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Mobile Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_mobile_logo ? <img className="img" src={formData?.sec_mobile_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <p className="text-bold lightColor">Desktop Image</p>
              <div className="imageInput couponImageInput">
                {formData?.sec_desktop_logo ? <img className="img" src={formData?.sec_desktop_logo} /> : "---"}
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <p className="text-bold lightColor">Information Tray</p>
              <div ref={secondaryInformation}></div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default CouponDetail;

import { useEffect, useRef, useState } from "react";

import { AuthLayout } from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";
// import CustomToast from "../../Components/CustomToast";

import axios from "axios";
import BASEURL from "../../Config/global";
import { DashboardLayout } from "../../Layout/DashboardLayout";
// import CryptoJS from 'crypto-js';
import { Eye, EyeSlash } from "../../Assets/svg";
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import BackButton from "../../Components/BackButton";
import PermissionDetails from "../../Components/PermissionDetail";
import moment from "moment";
import { successModal } from "../../Components/CustomModal";
// import VerifyModal from "./VerifyModal";

const AddUser = ({ coupon, closeCouponSignup }) => {


  const [formData, setFormData] = useState({});
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId')
  const navigate = useNavigate()
  const [successAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [isHousehold, setHousehold] = useState(null);
  const [isHouseholdKey, setHouseholdKey] = useState(null);
  const [passwordEye, setPasswordEye] = useState(true);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [roleName, setRoleName] = useState('');

  const [allRoles, setAllRoles] = useState();
  const [role, setRole] = useState(undefined)
  const [roleType, setRoleType] = useState("defualtRole")

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const decryptText = () => {
      setHouseholdKey(params.get('id'));
      const key = 'secret-key'; // Replace with your secret key
      const decryptedId = params.get('id')?.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
      //   const decryptedText = CryptoJS.AES.decrypt(decryptedId, key);
      //   const decrypted = decryptedText?.toString(CryptoJS.enc.Utf8);
      //   setHousehold(decrypted);
      console.log("decrypted")
    };
    if (params.get('id')) {
      decryptText();
    }
  }, [])
  useEffect(() => {
    getAllRoles()
    getAllPermissions()
    getUserInfoForEdit()

  }, []);
 
  const getUserInfoForEdit = async () => {
    if (!userId) {
      return
    }
    let data = '';
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/api/permissions/users/${userId}/permissions/`,
      headers: {},
      data: data
    };

    await axios.request(config)
      .then((response) => {
        const {data} = response.data
        if(data){
          setFormData({ ...data, checkPassword: data.password, date_of_birth:moment(data.date_of_birth).format('MM/DD/YYYY') })
          setRole(data.role.id)
          setRoleType(data.role.id ? "defualtRole" : 'customRole')
          setSelectedPermissions(data.permission)
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const getAllRoles = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/api/permissions/userrolelist/`,
      headers: {}
    };

    await axios.request(config)
      .then((response) => {
        const { data } = response.data;
        setAllRoles(data)
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const getAllPermissions = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/api/permissions/permissions`,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      }
    };
    await axios.request(config)
      .then((response) => {
        const { data } = response.data;
        setSelectedPermissions(data)
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const handleChange = (event) => {
    setError({ error: false, text: "" });
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRoleChange = (e) => {
    const value = e.target.value;
    setRoleName(value)
  }
  const validDateDateFormat = () => {
    if (formData?.date_of_birth !== '' && formData?.date_of_birth?.length === 10) {
      return true
    }
    return false
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validDateDateFormat()) {
      setLoading(false);
      setError({
        error: true,
        text: "Invalid date format. Please enter a valid date in mm/dd/yyyy format.",
      });
      return
    }
    if (roleType === 'defualtRole' && (!role || role === 'undefined')) {
      setLoading(false);
      setError({
        error: true,
        text: "Please select valid role",
      });
      return
    }
    if (roleType !== 'defualtRole' && roleName === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Role name cannot be empty",
      });
      return
    }
    if ((formData.email && formData.email != "") && formData.first_name && formData.last_name && formData.zip && formData.password && formData.checkPassword) {
      if ((formData.password.length < 6) || (formData.checkPassword.length < 6)) {
        setLoading(false);
        setError({
          error: true,
          text: "Password must contain 6 characters",
        });
      }
      else if (formData.password !== formData.checkPassword) {
        setLoading(false);
        setError({
          error: true,
          text: "Password doesn't match",
        });
      } else {
        try {
          const response = await handleAddUser()
         
          setLoading(false);
          if (!response.data.error) {
            successModal.fire({
              text: "Changes saved successfully",
              confirmButtonText: "Continue",
            });
            navigate(-1)
            setLoading(false);
            setError({ error: false, text: "" });
          } else {
            setLoading(false);
            setError({ error: true, text: response.data.message });
          }
        } catch (error) {
          setLoading(false);
          setError({
            error: true,
            text: "An error occurred. Please try again later.",
          });
        }
      }
    } else {
      setError({
        error: true,
        text: "Please fill all required fields",
      });
    }

  };

  const handleAddUser = async () => {
    let data = {
      "first_name": formData.first_name,
      "last_name": formData.last_name,
      "email": formData.email,
      "city": formData.city,
      "state": formData.state,
      "zip": formData.zip,
      "birthdate": formData.date_of_birth,
      "password": formData.password
    };
    if (roleType === 'defualtRole') {
      data["default_role"] = true;
      data["role_id"] = role
    } else {
      data["default_role"] = false
      data['role_name'] = roleName
      data["permissions"] = selectedPermissions
    }
    let config = {
      headers: {
        'Content-Type': 'application/json'
      },
      maxBodyLength: Infinity,
      data: JSON.stringify(data)
    }
    if (userId) {
      config.method = 'PATCH'
      config.url = `${BASEURL}/api/permissions/edit/user/` + userId
    }else {
      config.method = 'POST'
      config.url = `${BASEURL}/api/permissions/add/user`
    }
    return await axios.request(config)

  }

  const handler = (e) => {
    if (e.key === 'Backspace') {
      if (e.target.value.length == 3) {
        setFormData({ ...formData, 'date_of_birth': e.target.value.slice(0, 2) });
      } else if (e.target.value.length == 6) {
        setFormData({ ...formData, 'date_of_birth': e.target.value.slice(0, 5) });
      } else if (e.target.value.length <= 10 || e.target.value.length == '') {
        setFormData({ ...formData, 'date_of_birth': e.target.value });
      }
    }
  };

  const changeMe = (e) => {
    const input = e.target.value;
    const format = moment(input).format('MM/DD/YYYY')
    console.log("format", format)
    const sanitizedInput = input.replace(/[^0-9/]/g, '');
    const truncatedInput = sanitizedInput.slice(0, 10);
    const matches = truncatedInput.match(/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,})$/);
    if (matches) {
      const [, month, day, year] = matches;
      const isValidMonth = month === '' || (parseInt(month, 10) <= 12);
      const isValidDay = day === '' || (parseInt(day, 10) <= 31);
      const isValidYear = year === '' || (!isNaN(year));
      if (isValidMonth && isValidDay && isValidYear) {
        const formattedInput = [month, day, year].filter(Boolean).join('/');
        setFormData({ ...formData, 'date_of_birth': formattedInput });
      } else {
        console.log("Invalid date");
      }
    } else {
      console.log("Invalid date format");
    }
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value)
  }
  const verifiedCoupon = (type) => {
    closeCouponSignup();
  }

  return (
    <DashboardLayout>
      <div className="row mb-3">
        <div className="col-12 mb-2">
          <h2 className="mainTitle">
            <BackButton />
            {userId ? "Edit Employee Role Info" : "Add Employee Role Info"}
          </h2>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-lg-5 m-2">
            <CustomInput
              label="First Name"
              required
              id="fname"
              type="text"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="first_name"
              value={formData.first_name || ""}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-5  m-2">
            <CustomInput
              label="Last Name"
              required
              id="lname"
              type="text"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="last_name"
              value={formData.last_name || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5  m-2">
            <CustomInput
              label="Email"
              required
              id="email"
              type="email"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              autocomplete="new-email"
            />
          </div>
          <div className="col-lg-5 m-2">
            <CustomInput
              label="City"
              id="city"
              type="text"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="city"
              value={formData.city || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 m-2">
            <CustomInput
              label="State"
              id="state"
              type="text"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="state"
              value={formData.state || ""}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-5 m-2">
            <CustomInput
              label="Zip"
              required
              id="zip"
              type="text"
              labelClass="mainLabel"
              inputClass="mainInput"
              name="zip"
              value={formData.zip || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 m-2">
            <CustomInput
              label="Birthdate"
              id="date"
              type="text"
              placeholder="mm/dd/yyyy"
              labelClass="mainLabel"
              inputClass="mainInput place-holder-white"
              name="date_of_birth"
              value={formData?.date_of_birth || ""}
              onChange={changeMe}
              onKeyDown={handler}
            />
          </div>

        </div>
        
        {
          !userId ? (<div className="row">
            <div className="col-lg-5 m-2">
              <div className="inputWrapper">
                <label htmlFor="pass" className="mainLabel">
                  Password*
                </label>

                <div className="passwordWrapper">
                  <input
                    type={passwordEye ? "password" : "text"}
                    required
                    id="pass"
                    name="password"
                    value={formData.password || ""}
                    className={`mainInput passInput`}
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 m-2">
              <div className="inputWrapper">
                <label htmlFor="pass" className="mainLabel">
                  Confirm Password*
                </label>

                <div className="passwordWrapper">
                  <input
                    type={passwordEye ? "password" : "text"}
                    required
                    id="pass"
                    name="checkPassword"
                    value={formData.checkPassword || ""}
                    className={`mainInput passInput`}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                <button
                  type="button"
                  className="eyeButton"
                  onClick={() => setPasswordEye(!passwordEye)}
                >
                  {passwordEye ? <EyeSlash /> : <Eye />}
                </button>
              </div>
            </div>
          </div>
          </div>) : null
        }
         

          
       

        <div className="row ">
          <div className="col-lg-5 m-2">
            <label htmlFor="pass" className="mainLabel">
              Role Type*
            </label>
            <select
              name="category"
              id="category"
              className="mainInput"
              required
              onChange={(e) => setRoleType(e.target.value)}
            >
              <option value={"defualtRole"}>
                Default Role
              </option>
              <option value={"customRole"}>
                Custom Role
              </option>
            </select>
          </div>
          <div className="col-lg-5 m-2">
            {
              roleType === "defualtRole" ? (
                <>
                  <label htmlFor="pass" className="mainLabel">
                    Role*
                  </label>
                  <select
                    name="category"
                    id="category"
                    className="mainInput"
                    required
                    value={role || ""}
                    onChange={handleChangeRole}
                  >
                    <option value="undefined">Select Role</option>
                    {allRoles?.length > 0 && allRoles.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item?.name?.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <CustomInput
                  label="Role Name"
                  required
                  id="fname"
                  type="text"
                  labelClass="mainLabel"
                  inputClass="mainInput"
                  name="first_name"
                  value={roleName}
                  onChange={handleRoleChange}
                />
                    
              )
            }
          </div>

        </div>
        {
          roleType === "customRole" && (
            <div className="row mt-4">
              <div className="col-lg-10 m-1">
                <PermissionDetails selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} />
              </div>
            </div>
          )
        }
      </form>
      <div className="row">
      <div className="col-12">
          {error.error && (
            <div className="col-12">
              <p className="smallText lightColor">{error.text}</p>
            </div>
          )}
        </div>
      </div>
      <div className="row mt-4">
       
        <div className="col-12">
          {
            loading ? (

              <button class="primaryButton customButton">
                <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
              </button>
            )
              : (

                <CustomButton
                  variant="primaryButton"
                  text={"Save Changes"}
                  onClick={handleClick}
                />
              )
          }
        </div>
      </div>
      {/* <CustomToast
          show={successAlert}
          title={"Successful"}
          message={"Signed Up"}
          close={() => {
            setSuccessAlert(false);
          }}
        /> */}
      {/* {showVerifyModal && <VerifyModal coupon={coupon} verifiedCoupon={verifiedCoupon} />} */}
    </DashboardLayout>
  );
};

export default AddUser;

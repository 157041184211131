import React from "react";
import "./style.css";
import { stats3 } from "../../Assets/images";

const StatsCard = (props) => {
  return (
    <>
      <div className="statsCard">
        <div className="statsContent">
          <div className="statsImg">
            <img src={stats3} alt="Card Icon" />
          </div>
          <div className="statsData">
            <h3 className="statsNumber">{props.item.value}</h3>
            <p className="statsText">{props.item.title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsCard;
